<template>
  <section class="appointment_list_wrp">
    <div class="container-fluid">
      <div class="appointment_list_header_wrp">
        <div class="row align-items-center">
          <div class="col-auto">
            <h1 class="appointment_list_heading">
              <router-link class="btn back_btn" to="/dashboard/">
                <i aria-hidden="true" class="fa fa-chevron-left"></i>
              </router-link>Patient Feedbacks
            </h1>
          </div>
          <!-- <div class="col text-right">
            <div class="online_appointment_searchbar"><i class="fa fa-search"></i><input type="text"
                placeholder="Search" name="search_term" autocomplete="off" class="form-control"></div>
          </div> -->
        </div>
      </div>
      <div class="appointment_list_body_wrp">
        <div class="table-responsive">
          <table class="table" v-if="feedBackListState">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Review</th>
                <th class="text-right">Rating</th>
              </tr>
              <tr class="empty_cell">
                <th colspan="3"></th>
              </tr>
            </thead>
            <tbody v-if="feedBackList.length > 0">
              <tr v-for="item in feedBackList" :key="item.id">
                <td>{{ item.patient.first_name }} {{ item.patient.last_name }}</td>
                <td>{{ item.review }}</td>
                <td class="text-right"><i class="fa fa-star"></i> {{ item.rating }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">You don't have any feedbacks</td>
              </tr>
            </tbody>
          </table>
          <div class="compontent-loader" v-else>
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import toast from '@/services/toast';
import { bus } from '@/main';
export default {
  props: [],
  components: {
  },
  data() {
    return {
      feedBackListState: null,
      feedBackList: []
    };
  },
  mounted() {
    this.$store.dispatch('getDoctorReviewsListAction').then((res) => {
      this.feedBackListState = res.data.data
      this.feedBackList = this.feedBackListState.results
    })
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.appointment_list_wrp {
  .appointment_list_header_wrp {
    padding-bottom: 25px;

    .appointment_list_heading {
      color: #fff;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
    }

    .online_appointment_searchbar {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      .fa {
        font-size: 18px;
        cursor: pointer;
        color: #c0c0c0;
        position: absolute;
        top: 8px;
        left: 10px;
      }

      .form-control {
        background: rgba(228, 233, 237, 0.1);
        box-shadow: -5px 5px 10px rgba(82, 106, 133, 0.06);
        font-size: 18px;
        color: #fff;
        font-family: 'MyriadProRegular', sans-serif;
        border: none;
        height: auto;
        width: 280px;
        line-height: 36px;
        padding: 0px 15px 0 40px;
        -webkit-box-shadow: none;
        box-shadow: none;

        &::-moz-placeholder {
          color: #dfdfdf;
        }

        &::-webkit-input-placeholder {
          color: #dfdfdf;
        }

        &:focus::-webkit-input-placeholder {
          font-size: 0px;
        }

        &:focus::-moz-placeholder {
          font-size: 0px;
        }
      }
    }
  }

  .appointment_list_body_wrp {
    .table {
      margin: 0px;
      letter-spacing: 0.5px;
      color: #ffffff;
      border-collapse: separate;
      border-spacing: 0 3px;

      thead {
        th {
          border-bottom: 0;
          font-size: 19px;
          padding: 10px 8px;
          font-family: 'MyriadProRegular', sans-serif;
          text-transform: uppercase;
          border-top: 0;
          transition: all .5s ease;
          -webkit-transition: all .5s ease;
          -moz-transition: all ease .5s;
          background: rgba(228, 233, 237, .1);
          color: #87cefa;
        }

        .empty_cell th {
          padding: 0px !important;
          background: none !important;
        }
      }

      tbody {
        td {
          color: #fff;
          vertical-align: middle;
          padding: 10px 8px;
          font-size: 20px;
          font-family: 'MyriadProRegular', sans-serif;
          transition: all .5s ease;
          -webkit-transition: all .5s ease;
          -moz-transition: all ease .5s;
          height: 70px;
          line-height: 1.3;
          border-top: 0;
          background: rgba(228, 233, 237, .1);

          &.symptoms_item span:last-child i {
            display: none;
          }

          a {
            color: #fff;
            text-decoration: none;

            &:hover {
              color: #ffd400;
            }
          }
        }
      }
    }
  }

  .status_btn_wrp {
    .btn {
      padding: 8px 10px;
      line-height: 1;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .btn-danger {
    &:hover {
      background: #87cefa;
      color: #000 !important;
      border-color: #87cefa;
    }
  }

  .payment_status_text {
    font-size: 16px;
    display: block;
    color: #ffd400;
    margin-top: 4px;
  }

  .payment_inpro_btn {
    background: #ffd400 !important;
    border: none !important;
    color: #000 !important;

    &:hover {
      background: #87cefa !important;
    }
  }

  @media only screen and (max-width:767px) {
    .container-fluid {
      padding: 0px;
    }
  }
}
</style>